import { createStore } from 'vuex'

export default createStore({
  state: {
    // 是否显示footer
    ShowFooterComponent: true,

    // 是否显示navbar
    ShowNavbarComponent: true,

    name: 'wanghaha-development',
    
    userinfo: {
      name: ''
    }
  },
  getters: {
  },
  mutations: {
    updateValue (state, name) {
      state.name = name;
    },

    login(state, payload) {
      console.log('payload is :', payload);
      state.userinfo.name = payload.name;
    },

    // 隐藏footer
    hiddenFooter(state) {
      state.ShowFooterComponent = false;
    },
    // 显示footer
    showFooter(state) {
      state.ShowFooterComponent = true;
    },


    // 隐藏navbar
    hiddenNavbar(state) {
      state.ShowNavbarComponent = false;
    },
    // 显示navbar
    showNavbar(state) {
      state.ShowNavbarComponent = true;
    },
  },
  actions: {
  },
  modules: {
  }
})
