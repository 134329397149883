<template>
    <!-- 广告。 -->
    <div class="adv-view">
        <div class="left">
            <div>
                <img src="https://artlib.cn/img/artlib.png" alt=""/>
            </div>
            <div>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Artlib世界艺术鉴赏库</h2>
            </div>
        </div>
        <div class="center">
            <div class="describe">
                <svg t="1685084690259" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2395" width="32" height="32"><path d="M626.176 279.552c74.24 0 134.656 55.808 134.656 124.928 0 21.504-6.144 42.496-17.408 61.44-16.896 27.648-44.032 48.128-76.8 57.856-8.704 2.56-15.36 3.584-21.504 3.584-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6c1.024 0 3.072 0 5.632-1.024 22.016-6.144 39.424-18.944 49.152-35.84 6.656-10.752 9.728-22.528 9.728-34.816 0-40.448-37.376-73.728-82.944-73.728-15.872 0-31.232 4.096-45.056 11.776-24.064 13.824-38.4 36.864-38.4 61.952v214.528c0 43.52-24.064 83.456-64 105.984-21.504 12.288-45.568 18.432-70.144 18.432-74.24 0-134.656-55.808-134.656-124.928 0-21.504 6.144-42.496 17.408-61.44 16.896-27.648 44.032-48.128 76.8-57.856 9.216-2.56 15.36-3.584 21.504-3.584 14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6c-1.024 0-3.072 0-5.632 1.024-22.016 6.656-39.424 19.456-49.152 35.84-6.656 10.752-9.728 22.528-9.728 34.816 0 40.448 37.376 73.728 83.456 73.728 15.872 0 31.232-4.096 45.056-11.776 24.064-13.824 38.4-36.864 38.4-61.952V404.48c0-43.52 24.064-83.456 64-105.984 20.992-12.8 45.056-18.944 69.632-18.944z m-520.704 230.4c0 226.304 183.296 409.6 409.6 409.6s409.6-183.296 409.6-409.6-183.296-409.6-409.6-409.6-409.6 183.296-409.6 409.6z m-51.2 0c0-254.464 206.336-460.8 460.8-460.8s460.8 206.336 460.8 460.8-206.336 460.8-460.8 460.8-460.8-206.336-460.8-460.8z m0 0" fill="#ffffff" p-id="2396"></path></svg>
                &nbsp; &nbsp;微信小程序已开放使用
            </div>
        </div>
        <div class="right">
            <img src="@/assets/miniQRCode.jpg" alt=""/>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.adv-view {
    width: 100%;
    height: 108px;
    padding: 10px;
    border-radius: 10px;
    color: white;
    background-color: var(--artlibMasterColor);

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .describe {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 5px 10px;
            background-color: black;
            color: white;
            outline: 1px solid white;
        }
        
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            width: 80px;
            height: 80px;
            border-radius: 5px;
        }
    }
}
</style>