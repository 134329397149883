<template>
    <div class="scroll-view">
        <div @click="toLeft" v-if="hasSwitchButton" class="switch-button switch-button-left">
            <svg t="1684896647990" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11115" width="24" height="24"><path d="M802.909091 487.144727H333.661091l184.762182-170.589091a34.909091 34.909091 0 1 0-47.36-51.269818l-250.391273 231.098182a34.955636 34.955636 0 0 0 0 51.316364l250.391273 231.098181a34.862545 34.862545 0 0 0 49.338182-1.954909 34.909091 34.909091 0 0 0-1.978182-49.338181l-184.762182-170.542546H802.909091a34.909091 34.909091 0 1 0 0-69.818182" fill="#1a73e8" p-id="11116"></path></svg>
        </div>

        <div @click="toRight" v-if="hasSwitchButton" class="switch-button switch-button-right">
            <svg t="1684896781045" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12964" width="24" height="24"><path d="M244.363636 556.939636h469.248l-184.762181 170.565819a34.909091 34.909091 0 1 0 47.36 51.29309l250.391272-231.121454a34.955636 34.955636 0 0 0 0-51.293091l-250.391272-231.121455a34.862545 34.862545 0 0 0-49.338182 1.95491 34.909091 34.909091 0 0 0 1.978182 49.338181l184.762181 170.565819H244.363636a34.909091 34.909091 0 1 0 0 69.818181" fill="#1a73e8" p-id="12965"></path></svg>
        </div>

        <div 
        :class="{'data-list1': style1 ? style1 : true, 'data-list2': style2, 'data-list3': style3}"
        ref="scrollDataList">
            <div class="item-view" v-for="(item, index) in data" :key="index" @click="toDetail(item.id)" @mouseup="mouseUpFn">
                <div class="img-view" oncontextmenu="return false" onselectstart="return false" oncopy="return false">
                    <ArtImgLayer :image="'https://artlib.cn/' + item.imgs"/>
                </div>

                <Loading v-if="!showBigImage" class="loading"/>

                <div class="info">
                    <div class="name space-mh5">{{item.name}}</div>
                    <div class="space-mh5" v-if="item.imp_author_name">
                        <span v-for="child in splitStr(item.imp_author_name)">{{ child }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch} from 'vue'
import ArtImgLayer from "@/components/ArtImgLayer.vue"
import router from "@/router"



// 分割字符串
function splitStr(content) {
    return content.split(';')
}

// 跳转到艺术品详情
function toDetail(id) {
    const { href } = router.resolve({
        path: "/artworkFullScreen",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}

// 获取 props 参数
const props = defineProps(["data", "style1", "style2", "style3"])


onMounted(()=>{
    checkShowSiwtch();
})

watch(() => props.data, (newValue, oldValue) => {
    if(newValue.length>0) {
        setTimeout(()=>{
            checkShowSiwtch();
        }, 100);
    }
})

let scrollDataList = ref(null)
let scrollLeft = ref(0);
let hasSwitchButton = ref(true);

// 向右滚动 👉
let toRight = () => {
    scrollDataList.value.scrollTo({
        left: scrollDataList.value.scrollLeft + 500,
        behavior: 'smooth'
    });

    scrollLeft.value = scrollDataList.value.scrollLeft;
}

// 向左滚动 👈
let toLeft = () => {
    scrollDataList.value.scrollTo({
        left: scrollDataList.value.scrollLeft - 500,
        behavior: 'smooth'
    });

    scrollLeft.value = scrollDataList.value.scrollLeft;
}

// 初始判断是否显示 switch按钮
let checkShowSiwtch = () => {
    let scrollWidth = scrollDataList.value.scrollWidth;
    let clientWidth = scrollDataList.value.clientWidth;
    if(scrollWidth!=clientWidth) {
        hasSwitchButton.value = true;
    } else {
        hasSwitchButton.value = false;
    }
}

// 调整窗口大小事件
window.addEventListener("resize", function() {
    let scrollWidth = scrollDataList.value.scrollWidth;
    let clientWidth = scrollDataList.value.clientWidth;

    if(scrollWidth!=clientWidth) {
        hasSwitchButton.value = true;
    } else {
        hasSwitchButton.value = false;
    }
});
</script>

<style lang="scss" scoped>
.scroll-view {
    position: relative;
    max-width: 1200px;

    .switch-button-left {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 100;
    }

    .switch-button-right {
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 100;
    }
}
.switch-button {
    width: 56px;
    height: 56px;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgb(60,64,67,0.3), 0 2px 6px 2px rgb(60,64,67,0.15);
    border-radius: 50%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover {
        background-color: #efefef;
    }
    &:active {
        background-color: white;
    }
}

// 样式1
.data-list1 {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;

    .item-view {
        margin: 2px;
        min-width: 236px;
        max-width: 236px;
        min-height: 236px;
        max-height: 236px;
        cursor: pointer;
        overflow: hidden;

        position: relative;

        .img-view {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: #ddd;
        }

        .info {
            // 禁止鼠标事件：防止影响 :hover 效果
            pointer-events: none;
            
            position: absolute;
            bottom: 10px;
            left: 10px;
            color: white;
            z-index: 10;
        }
    }
}

// 样式2
.data-list2 {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;

    
    .item-view:first-child, .item-view:last-child {
        margin-top: 8px;
    }
    .item-view:not(:first-child):not(:last-child) {
        margin: 8px;
    }
    .item-view:first-child {
        margin-right: 10px;
    }

    .item-view {
        min-width: 192px;
        max-width: 192px;
        min-height: 192px;
        max-height: 192px;
        border-radius: 10px;

        cursor: pointer;
        overflow: hidden;

        position: relative;

        .img-view {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: #ddd;
        }

        .info {
            position: absolute;
            bottom: 10px;
            left: 10px;
            color: white;
            z-index: 10;
        }
    }
}

// 样式3
.data-list3 {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;

    .item-view:first-child, .item-view:last-child {
        margin-top: 8px;
    }
    .item-view:not(:first-child):not(:last-child) {
        margin: 8px;
    }
    .item-view:first-child {
        margin-right: 10px;
    }

    .item-view {
        margin: 2px;
        min-width: 389px;
        max-width: 389px;
        min-height: 218px;
        max-height: 218px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 10px;

        position: relative;

        .img-view {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: #ddd;
        }

        .info {
            position: absolute;
            bottom: 10px;
            left: 10px;
            color: white;
            z-index: 10;
        }
    }
}
</style>