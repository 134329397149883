import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { layer } from  "@layui/layer-vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/artist',
    name: 'artist',
    component: () => import("../views/Artist.vue")
  },
  {
    path: '/artistDetail',
    name: 'artistDetail',
    component: () => import("../views/ArtistDetail.vue")
  },
  {
    path: '/artistWorks',
    name: 'artistWorks',
    component: () => import("../views/ArtistWorks.vue")
  },
  {
    path: '/typeWorks',
    name: 'typeWorks',
    component: () => import("../views/TypeWorks.vue")
  },
  // {
  //   path: '/artworkCollections',
  //   name: 'artworkCollections',
  //   component: ()=>import('../views/ArtworkCollections.vue')
  // },
  {
    path: '/artwork',
    name: 'artwork',
    component: () => import("../views/Artwork.vue")
  },
  {
    path: '/artworkFullScreen',
    name: 'artworkFullScreen',
    component: () => import("../views/ArtworkFullScreen.vue")
  },
  // {
  //   path: '/museumCollections',
  //   name: 'museumCollections',
  //   component: ()=>import('../views/MuseumCollections.vue')
  // },
  {
    path: '/museum',
    name: 'museum',
    component: () => import("../views/Museum.vue")
  },
  {
    path: '/museumDetail',
    name: 'museumDetail',
    component: () => import("../views/MuseumDetail.vue")
  },
  // {
  //   path: '/storyCollections',
  //   name: 'storyCollections',
  //   component: ()=>import('../views/StoryCollections.vue')
  // },
  {
    path: '/story',
    name: 'story',
    component: () => import("../views/Story.vue")
  },
  {
    path: '/storyDetail',
    name: 'storyDetail',
    component: () => import("../views/StoryDetail.vue")
  },
  // {
  //   path: '/specialCollections',
  //   name: 'specialCollections',
  //   component: ()=>import('../views/SpecialCollections.vue')
  // },
  {
    path: '/special',
    name: 'special',
    component: () => import("../views/Special.vue")
  },
  {
    path: '/specialDetail',
    name: 'specialDetail',
    component: () => import("../views/SpecialDetail.vue")
  },
  {
    path: '/search',
    name: 'search',
    component: () => import("../views/Search.vue")
  }, 
  {
    path: '/activity/shuou',
    name: 'shuou',
    component: () => import("../views/activity/Shuou.vue")
  }, 
  {
    path: '/activity/yuedu',
    name: 'yuedu',
    component: () => import("../views/activity/Yuedu.vue")
  }, 
  {
    path: '/activity/list',
    name: 'list',
    component: () => import("../views/activity/List.vue")
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/Login.vue")
  },
  {
    path: '/about',
    name: 'about',
    component: () => import("../views/About.vue")
  },
  {
    path: '/exhibition/list',
    name: 'exhibition_list',
    component: () => import("../views/exhibition/List.vue")
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import("../views/Recharge.vue")
  },
  {
    path: '/profile/organization',
    name: 'organization',
    component: () => import("../views/profile/Organization.vue")
  },
  {
    path: '/profile/personal',
    name: 'personal',
    component: () => import("../views/profile/Personal.vue")
  },
  {
    path: '/collect/artist',
    name: 'collect_artist',
    component: () => import("../views/profile/CollectArtist.vue")
  },
  {
    path: '/collect/artwork',
    name: 'collect_artwork',
    component: () => import("../views/profile/CollectArtwork.vue")
  },
  {
    path: '/collect/museum',
    name: 'collect_museum',
    component: () => import("../views/profile/CollectMuseum.vue")
  },
  {
    path: '/collect/story',
    name: 'collect_story',
    component: () => import("../views/profile/CollectStory.vue")
  },
  {
    path: '/collect/special',
    name: 'collect_special',
    component: () => import("../views/profile/CollectSpecial.vue")
  },
  {
    path: '/test',
    name: 'test',
    component: () => import("../views/Test.vue")
  },
  {
    path: '/WxLoginCallBack',
    name: 'WxLoginCallBack',
    component: () => import("../views/WxLoginCallBack.vue")
  },
  {
    path: '/rechargeSuccess',
    name: 'rechargeSuccess',
    component: () => import("../views/RechargeSuccess.vue")
  },
  {
    path: '/test2',
    name: 'test2',
    component: () => import("../views/Test2.vue")
  },
  {
    path: '/test3',
    name: 'test3',
    component: () => import("../views/Test3.vue")
  },
  {
    path: '/test4',
    name: 'test4',
    component: () => import("../views/Test4.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 导航守卫
router.beforeEach((to, from, next)=>{
  let token = localStorage.getItem('token');
  if ((to.path != '/login' 
  && to.path != '/WxLoginCallBack' 
  && to.path != '/'
  && to.path != '/about'
  && to.path != '/artist'
  && to.path != '/artwork'
  && to.path != '/museum'
  && to.path != '/story'
  && to.path != '/special'
  && to.path != '/artworkCollections'
  && to.path != '/museumCollections'
  && to.path != '/storyCollections'
  && to.path != '/specialCollections'
  && to.path != '/exhibition/list'
  
  ) && !token) {
    layer.msg("请登录", { time: 1000 })
    next({path: '/login'})
  } else {
    next()
  }
})


// 跳转页面时，滚动条滚动到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
