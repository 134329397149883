<template>
    <div class="container-view">
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>


        <div class="center-view">
            <Style1 />
        </div>


        <br><br><br>

        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>

        <div class="center-view">
            <Adv1 />
        </div>
        
        <br><br><br>
        <div class="area-style-view2">
            <div class="kind-name space-mh10">
                <div class="title">Keep exploring...</div>
                <div class="sub-title">Dive into culture from around the world</div>
            </div>
            
            <div class="center-view">
                <FlexCard />
            </div>
        </div>

        <br><br><br>

        <!-- <ScaleCard2 /> -->
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <ScaleCard2 />
        </div>

        <br><br><br>


        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <StoryScaleCard />
        </div>

        <br><br><br>

        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <SpecialScaleCard />
        </div>

        <br><br><br>
        


        <div class="area-style-view1">
            <div class="kind-name space-mh10">
                <div class="title">Keep exploring...</div>
                <div class="sub-title">Dive into culture from around the world</div>
            </div>

            <div class="center-view">
                <FlexCard />
            </div>
        </div>



        <br><br><br>
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <G_MuseumComponent :data="museums"/>
        </div>

        <br><br><br>
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <G_ArtistComponent :data="artists"/>
        </div>

        <br><br><br>
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <G_ArtworkComponent :data="works"/>
        </div>

        <br><br><br>
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <G_StoryComponent :data="stories"/>
        </div>


        <br><br><br>
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <G_SpecialComponent :data="specials"/>
        </div>


        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <G_Card1 />
        </div>


        <br><br><br>
        <div class="kind-name space-mh10">
            <div class="title">Keep exploring...</div>
            <div class="sub-title">Dive into culture from around the world</div>
        </div>
        <div class="center-view">
            <Style2 />
        </div>


        <br><br><br>
        
    </div>
</template>

<script setup>
import Style1 from "@/components/Style1.vue"
import Style2 from "@/components/Style2.vue"
import ScaleCard from "@/components/ScaleCard.vue"
import ScaleCard2 from "@/components/ScaleCard2.vue"
import StoryScaleCard from "@/components/StoryScaleCard.vue"
import SpecialScaleCard from "@/components/SpecialScaleCard.vue"
import FlexCard from "@/components/FlexCard.vue"
import Adv1 from "@/components/Adv1.vue"
import G_Card1 from "@/components/G_Card1.vue"

import G_MuseumComponent from "@/components/G_MuseumComponent"
import G_ArtistComponent from "@/components/G_ArtistComponent"
import G_ArtworkComponent from "@/components/G_ArtworkComponent"
import G_StoryComponent from "@/components/G_StoryComponent"
import G_SpecialComponent from "@/components/G_SpecialComponent"
import {ref, onMounted} from "vue"
import { getReq, getReqWithToken, postReq, postReqWithToken} from "@/api"

let works = ref([]);
let artists = ref([]);
let museums = ref([]);
let stories = ref([]);
let specials = ref([]);

onMounted(()=>{
    getData();
})


// 获取数据
function getData() {
    getReq(`/home`).then(ret=>{
        let resp = ret.data.data;
        works.value = resp.works;
        artists.value = resp.artists;
        museums.value = resp.museums;
        stories.value = resp.stories;
        specials.value = resp.specials;
    })
}

</script>

<style lang="scss" scoped>
.container-view {
    // width: 1200px;
    padding: 0;

    margin: 0 auto;
}
body {
    margin: 0;
    padding: 0;
}

.center-view {
    max-width: 1200px;
    width: 1200px;
    margin: 0 auto;
}

.area-style-view1 {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    background-color: #4fc3f7; padding: 100px; width: 100% !important;
}
.area-style-view2 {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    background-color: #fbe9e7; padding: 100px; width: 100% !important;
}


.kind-name {
    width: 1000px;
    margin: 0 auto;
    text-align: center;

    .title {
        font-size: 30px;
    }
    .sub-title {

    }
}
</style>