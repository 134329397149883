<template>
    <div class="container-view">
        <div class="footer-menu-view">
            <div class="box">
                <div class="title">栏目</div>
                <div class="space-mh10 item light-text" @click="router.push('/artist')">艺术家</div>
                <div class="space-mh10 item light-text" @click="router.push('/artworkCollections')">艺术品</div>
                <div class="space-mh10 item light-text" @click="router.push('/museumCollections')">艺术机构</div>
                <div class="space-mh10 item light-text" @click="router.push('/storyCollections')">艺术故事</div>
                <div class="space-mh10 item light-text" @click="router.push('/specialCollections')">艺术专题</div>
                <div class="space-mh10 item light-text" @click="router.push('/exhibition/list')">线上展览</div>
            </div>
            <div class="box">
                <div class="title">类目</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0208&typeName=油画艺术')">油画艺术</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0213&typeName=素描艺术')">素描艺术</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0204&typeName=版画艺术')">版画</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0234&typeName=佛教艺术')">佛教艺术</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0227&typeName=工艺美术')">工艺美术</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0223&typeName=日本绘画')">日本绘画</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0239&typeName=宗教艺术')">宗教艺术</div>
                <div class="space-mh10 item light-text" @click="router.push('/artwork?code=0204&typeName=版画艺术')">版画艺术</div>
            </div>
            <div class="box">
                <div class="title">活动</div>
                <div class="space-mh10 item light-text">书偶活动</div>
            </div>
            <div class="box">
                <div class="title">官方</div>
                <div class="space-mh10 item light-text" @click="toRouter('/about')">关于我们</div>
            </div>

            <div class="footer-copyright-view">
                © 2023 Artlib世界艺术鉴赏库, Inc. All rights reserved.
            </div>
        </div>
    </div>
</template>

<script setup>
const { default: router }=require("@/router");

function toRouter(path) {
    router.push(path);
}
</script>

<style lang="scss" scoped>
.container-view {
    // background-color: #f8f9fa;
    background-color: white;
}
.footer-menu-view {
    max-width: 1200px;
    margin: 0 auto;
    height: 300px;
    padding: 100px 0;
    border-top: 1px solid #eee;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .box {
        margin-right: 50px;

        .title {
            font-size: 1.3em;
            font-weight: bold;
        }

        .item {
            cursor: pointer;
            font-size: 1em;
        }
    }
}

.footer-copyright-view {
    width: 100%;
    height: 100px;
    border-top: 1px solid #eee;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>