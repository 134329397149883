<template>
    <div>
        <div class="works">
            <div class="work-item item1" ref="item1" @mouseover="mouseOver('item1', $event)" @mouseout="mouseOut" :style="{'transform': data.item1.scale, 'z-index': data.item1.zIndex}">
                <div class="work-img">
                    <img :src="'https://artlib.cn/'+works_first.imgs" alt="">
                </div>
                <div class="info-view">
                    <div class="work-title">{{ works_first.name }}</div>
                    <div class="work-author">{{works_first.imp_author_name}}</div>
                </div>
                <div class="work-layer" v-if="data.item1.showLayer"></div>
            </div>

            <div class="work-item item2" ref="item2" @mouseover="mouseOver('item2', $event)" @mouseout="mouseOut" :style="{'transform': data.item2.scale, 'z-index': data.item2.zIndex}">
                <div class="work-img">
                    <img :src="'https://artlib.cn/'+works_first.imgs" alt="">
                </div>
                <div class="info-view">
                    <div class="work-title">{{ works_first.name }}</div>
                    <div class="work-author">{{works_first.imp_author_name}}</div>
                </div>
                <div class="work-layer" v-if="data.item2.showLayer"></div>
            </div>

            <div class="work-item item3" ref="item3" @mouseover="mouseOver('item3', $event)" @mouseout="mouseOut" :style="{'transform': data.item3.scale, 'z-index': data.item3.zIndex}">
                <div class="work-img">
                    <img :src="'https://artlib.cn/'+works_first.imgs" alt="">
                </div>
                <div class="info-view">
                    <div class="work-title">{{ works_first.name }}</div>
                    <div class="work-author">{{works_first.imp_author_name}}</div>
                </div>
                <div class="work-layer" v-if="data.item3.showLayer"></div>
            </div>

            <div class="work-item item4" ref="item4" @mouseover="mouseOver('item4', $event)" @mouseout="mouseOut" :style="{'transform': data.item4.scale, 'z-index': data.item4.zIndex}">
                <div class="work-img">
                    <img :src="'https://artlib.cn/'+works_first.imgs" alt="">
                </div>
                <div class="info-view">
                    <div class="work-title">{{ works_first.name }}</div>
                    <div class="work-author">{{works_first.imp_author_name}}</div>
                </div>
                <div class="work-layer" v-if="data.item4.showLayer"></div>
            </div>

            <div class="work-item item5" ref="item5" @mouseover="mouseOver('item5', $event)" @mouseout="mouseOut" :style="{'transform': data.item5.scale, 'z-index': data.item5.zIndex}">
                <div class="work-img">
                    <img :src="'https://artlib.cn/'+works_first.imgs" alt="">
                </div>
                <div class="info-view">
                    <div class="work-title">{{ works_first.name }}</div>
                    <div class="work-author">{{works_first.imp_author_name}}</div>
                </div>
                <div class="work-layer" v-if="data.item5.showLayer"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getReq, getReqWithToken, postReq, postReqWithToken} from "@/api"
import { onMounted, ref } from "vue"

let works = ref([]);
let artists = ref([]);
let museums = ref([]);
let stories = ref([]);
let specials = ref([]);

let works_first = ref({});
let artists_first = ref({});
let museums_first = ref({});
let stories_first = ref({});
let specials_first = ref({});

onMounted(()=>{
    getHomeData();
})


// 获取数据
function getHomeData() {
    getReq(`/home`).then(ret=>{
        let resp = ret.data.data;
        works.value = resp.works;
        artists.value = resp.artists;
        museums.value = resp.museums;
        stories.value = resp.stories;
        specials.value = resp.specials;

        works_first.value = resp.works[0];
        artists_first.value = resp.artists[0];
        museums_first.value = resp.museums[0];
        stories_first.value = resp.stories[0];
        specials_first.value = resp.specials[0];
    })
}







// scale config.
const data = 
ref({
    item1: {
        zIndex: 1,
        scale: 'scale(0.6)',
        showLayer: true
    },
    item2: {
        zIndex: 2,
        scale: 'scale(0.8)',
        showLayer: true
    },
    item3: {
        zIndex: 3,
        scale: 'scale(1)',
        showLayer: false
    },
    item4: {
        zIndex: 2,
        scale: 'scale(0.8)',
        showLayer: true
    },
    item5: {
        zIndex: 1,
        scale: 'scale(0.6)',
        showLayer: true
    },
})

function mouseOver(element, event) {
    if (element == "item1") {
        data.value.item1.zIndex = 10
        data.value.item2.zIndex = 4
        data.value.item3.zIndex = 3
        data.value.item4.zIndex = 2
        data.value.item5.zIndex = 1

        data.value.item1.scale = 'scale(1.1)'
        data.value.item2.scale = 'scale(0.9)'
        data.value.item3.scale = 'scale(0.8)'
        data.value.item4.scale = 'scale(0.7)'
        data.value.item5.scale = 'scale(0.6)'

        data.value.item1.showLayer = false
        data.value.item2.showLayer = true
        data.value.item3.showLayer = true
        data.value.item4.showLayer = true
        data.value.item5.showLayer = true
    } else if (element == "item2") {
        data.value.item1.zIndex = 1
        data.value.item2.zIndex = 10
        data.value.item3.zIndex = 3
        data.value.item4.zIndex = 2
        data.value.item5.zIndex = 1

        data.value.item1.scale = 'scale(0.9)'
        data.value.item2.scale = 'scale(1.1)'
        data.value.item3.scale = 'scale(0.9)'
        data.value.item4.scale = 'scale(0.8)'
        data.value.item5.scale = 'scale(0.7)'

        data.value.item1.showLayer = true
        data.value.item2.showLayer = false
        data.value.item3.showLayer = true
        data.value.item4.showLayer = true
        data.value.item5.showLayer = true
    } else if (element == "item3") {
        data.value.item1.zIndex = 1
        data.value.item2.zIndex = 2
        data.value.item3.zIndex = 10
        data.value.item4.zIndex = 2
        data.value.item5.zIndex = 1

        data.value.item1.scale = 'scale(0.8)'
        data.value.item2.scale = 'scale(0.9)'
        data.value.item3.scale = 'scale(1.1)'
        data.value.item4.scale = 'scale(0.9)'
        data.value.item5.scale = 'scale(0.8)'

        data.value.item1.showLayer = true
        data.value.item2.showLayer = true
        data.value.item3.showLayer = false
        data.value.item4.showLayer = true
        data.value.item5.showLayer = true
    } else if (element == "item4") {
        data.value.item1.zIndex = 1
        data.value.item2.zIndex = 2
        data.value.item3.zIndex = 3
        data.value.item4.zIndex = 10
        data.value.item5.zIndex = 1

        data.value.item1.scale = 'scale(0.7)'
        data.value.item2.scale = 'scale(0.8)'
        data.value.item3.scale = 'scale(0.9)'
        data.value.item4.scale = 'scale(1.1)'
        data.value.item5.scale = 'scale(0.9)'

        data.value.item1.showLayer = true
        data.value.item2.showLayer = true
        data.value.item3.showLayer = true
        data.value.item4.showLayer = false
        data.value.item5.showLayer = true
    } else if (element == "item5") {
        data.value.item1.zIndex = 1
        data.value.item2.zIndex = 2
        data.value.item3.zIndex = 3
        data.value.item4.zIndex = 4
        data.value.item5.zIndex = 10

        data.value.item1.scale = 'scale(0.6)'
        data.value.item2.scale = 'scale(0.7)'
        data.value.item3.scale = 'scale(0.8)'
        data.value.item4.scale = 'scale(0.9)'
        data.value.item5.scale = 'scale(1)'

        data.value.item1.showLayer = true
        data.value.item2.showLayer = true
        data.value.item3.showLayer = true
        data.value.item4.showLayer = true
        data.value.item5.showLayer = false
    }
}

function mouseOut(e) {
    
}
</script>

<style lang="scss" scoped>
/* work */
.works {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;

    .work-item::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px -100px 45px -27px rgba(0,0,0,0.7);
    }

    .work-item {
        width: 100%;
        height: 450px;
        border-radius: 8px;
        cursor: pointer;

        transition: all 0.2s;
        overflow: hidden;
        position: relative;

        .work-layer {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 0;
            left: 0;
        }

        .work-img {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .info-view {
            width: 90%;

            position: absolute;
            bottom: 10px;
            left: 10px;
            color: white;

            .work-title {
                font-size: 18px;
            }

            .work-author {
                font-size: 14px;
            }
        }
    }

    .item1 {
        background-color: #ddd;
        z-index: 1;
    }
    .item2 {
        background-color: #ddd;
        margin-left: -150px;
        z-index: 2;
    }
    .item3 {
        background-color: #ddd;
        margin-left: -150px;
        z-index: 10;
    }
    .item4 {
        background-color: #ddd;
        margin-left: -150px;
        z-index: 2;
    }
    .item5 {
        background-color: #ddd;
        margin-left: -150px;
        z-index: 1;
    }
}
</style>
