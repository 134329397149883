<template>
    <div class="scroll-view">
        <div @click="toLeft" v-if="hasSwitchButton" class="switch-button switch-button-left">
            <svg t="1684896647990" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11115" width="24" height="24"><path d="M802.909091 487.144727H333.661091l184.762182-170.589091a34.909091 34.909091 0 1 0-47.36-51.269818l-250.391273 231.098182a34.955636 34.955636 0 0 0 0 51.316364l250.391273 231.098181a34.862545 34.862545 0 0 0 49.338182-1.954909 34.909091 34.909091 0 0 0-1.978182-49.338181l-184.762182-170.542546H802.909091a34.909091 34.909091 0 1 0 0-69.818182" fill="#1a73e8" p-id="11116"></path></svg>
        </div>

        <div @click="toRight" v-if="hasSwitchButton" class="switch-button switch-button-right">
            <svg t="1684896781045" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12964" width="24" height="24"><path d="M244.363636 556.939636h469.248l-184.762181 170.565819a34.909091 34.909091 0 1 0 47.36 51.29309l250.391272-231.121454a34.955636 34.955636 0 0 0 0-51.293091l-250.391272-231.121455a34.862545 34.862545 0 0 0-49.338182 1.95491 34.909091 34.909091 0 0 0 1.978182 49.338181l184.762181 170.565819H244.363636a34.909091 34.909091 0 1 0 0 69.818181" fill="#1a73e8" p-id="12965"></path></svg>
        </div>

        <div class="data-list cards" ref="scrollDataList">
            <div class="item-view card-view" v-for="(item, index) in works" :key="index" @click="toDetail(item.id)" @mouseup="mouseUpFn">
                <div class="img-view">
                    <ArtImgLayer2 :image="'https://artlib.cn/'+item.imgs"/>
                </div>

                <div class="info-view">
                    <div class="title">{{item.name}}</div>
                    <div class="sub-title">{{item.en_name}}</div>
                    <div class="sub-title">{{item.imp_author_name}}</div>
                </div>

                <div class="button-view">浏览作品</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ArtImgLayer2 from "@/components/ArtImgLayer2.vue"

import { getReq, getReqWithToken, postReq, postReqWithToken} from "@/api"
import { onMounted, ref, watch } from "vue"

let works = ref([]);
let artists = ref([]);
let museums = ref([]);
let stories = ref([]);
let specials = ref([]);

let works_first = ref({});
let artists_first = ref({});
let museums_first = ref({});
let stories_first = ref({});
let specials_first = ref({});

onMounted(()=>{
    getHomeData();

    checkShowSiwtch();
})


// 获取数据
function getHomeData() {
    getReq(`/home`).then(ret=>{
        let resp = ret.data.data;
        works.value = resp.works;
        artists.value = resp.artists;
        museums.value = resp.museums;
        stories.value = resp.stories;
        specials.value = resp.specials;

        works_first.value = resp.works[0];
        artists_first.value = resp.artists[0];
        museums_first.value = resp.museums[0];
        stories_first.value = resp.stories[0];
        specials_first.value = resp.specials[0];
    })
}









// 滚动相关
watch(() => works.value, (newValue, oldValue) => {
    if(newValue.length>0) {
        setTimeout(()=>{
            checkShowSiwtch();
        }, 100);
    }
})

let scrollDataList = ref(null)
let scrollLeft = ref(0);
let hasSwitchButton = ref(true);

// 向右滚动 👉
let toRight = () => {
    scrollDataList.value.scrollTo({
        left: scrollDataList.value.scrollLeft + 500,
        behavior: 'smooth'
    });

    scrollLeft.value = scrollDataList.value.scrollLeft;
}

// 向左滚动 👈
let toLeft = () => {
    scrollDataList.value.scrollTo({
        left: scrollDataList.value.scrollLeft - 500,
        behavior: 'smooth'
    });

    scrollLeft.value = scrollDataList.value.scrollLeft;
}

// 初始判断是否显示 switch按钮
let checkShowSiwtch = () => {
    let scrollWidth = scrollDataList.value.scrollWidth;
    let clientWidth = scrollDataList.value.clientWidth;
    if(scrollWidth!=clientWidth) {
        hasSwitchButton.value = true;
    } else {
        hasSwitchButton.value = false;
    }
}

// 调整窗口大小事件
window.addEventListener("resize", function() {
    let scrollWidth = scrollDataList.value.scrollWidth;
    let clientWidth = scrollDataList.value.clientWidth;
    if(scrollWidth!=clientWidth) {
        hasSwitchButton.value = true;
    } else {
        hasSwitchButton.value = false;
    }
});
</script>

<style lang="scss" scoped>
.cards {
    display: flex;
    flex-direction: row;

    .card-view {
        margin: 10px;
        
        min-width: 288px;
        height: 460px;
        border-radius: 10px;
        background-color: #dadce0;
        overflow: hidden;
        position: relative;
        cursor: pointer;


    
        .info-view {
            width: 100%;
            box-sizing: border-box;
            pointer-events: none;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            z-index: 10;

            .title {
                width: 100%;
                font-size: 18px;
                text-align: center;
                font-weight: bold;
            }

            .sub-title {
                text-align: center;   
            }
        }

        .button-view {
            pointer-events: none;
            
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            border: 2px solid white;
            padding: 10px 20px;
            border-radius: 3px;

            z-index: 10;
        }

        .img-view {
            width: 100%;
            height: 100%;
        }
    }
}



.scroll-view {
    position: relative;
    max-width: 1200px;

    .switch-button-left {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 100;
    }

    .switch-button-right {
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 100;
    }
}
.switch-button {
    width: 56px;
    height: 56px;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgb(60,64,67,0.3), 0 2px 6px 2px rgb(60,64,67,0.15);
    border-radius: 50%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover {
        background-color: #efefef;
    }
    &:active {
        background-color: white;
    }
}

.data-list {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>