<template>
    <div>
        <div class="cards">
            <div 
            class="card-item card1"  
            v-for="(item, index) in specials"  
            :class="{'nav-item': true, 'active': activeItem === index}"
            @mouseover="mouseOver(index)" @click="toSpecialDetail(item.id)">
                <div class="img-view">
                    <img :src="'https://artlib.cn/' + item.imgs" alt=""/>
                </div>

                <div class="info-view">
                    {{ item.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getReq, getReqWithToken, postReq, postReqWithToken} from "@/api"
import { onMounted, ref } from "vue"
import router from "@/router"

let works = ref([]);
let artists = ref([]);
let museums = ref([]);
let stories = ref([]);
let specials = ref([]);

let works_first = ref({});
let artists_first = ref({});
let museums_first = ref({});
let stories_first = ref({});
let specials_first = ref({});

onMounted(()=>{
    getHomeData();
})


// 获取数据
function getHomeData() {
    getReq(`/home`).then(ret=>{
        let resp = ret.data.data;
        works.value = resp.works;
        artists.value = resp.artists;
        museums.value = resp.museums;
        stories.value = resp.stories;
        specials.value = resp.specials.slice(0, 5);

        works_first.value = resp.works[0];
        artists_first.value = resp.artists[0];
        museums_first.value = resp.museums[0];
        stories_first.value = resp.stories[0];
        specials_first.value = resp.specials[0];
    })
}


// 设置激活项
let activeItem = ref(0);
function mouseOver(index, event) {
    activeItem.value = index; // 更新选中项
};



// 到艺术家详细信息页面
function toArtistDetail(id) {
    const { href } = router.resolve({
        path: "/artistDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}

// 到艺术品详细信息页面
function toArtworkDetail(id) {
    const { href } = router.resolve({
            path: "/artworkFullScreen",
            query: {
                id: id
            }
        });
    window.open(href, '_blank');
}

// 跳转到艺术机构详情页面
function toMuseumDetail(id) {
    router.push({
        path: '/museumDetail',
        query: {
            id: id
        }
    })
}

// 跳转到艺术故事详情页面
function toStoryDetail(id) {
    const { href } = router.resolve({
        path: "/storyDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}

// 跳转到艺术专题详情页面
function toSpecialDetail(id) {
    const { href } = router.resolve({
        path: "/specialDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}
</script>

<style lang="scss" scoped>
.cards {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
}
.card-item {
    width: 180px;
    height: 500px;
    // transition: all 0.2s;
    // transition: all 0.5s ease-out;
    // transition: all 0.5s;
    transition: 0.5s cubic-bezier(.19,1,.22,1),-webkit-transform .5s cubic-bezier(.19,1,.22,1);

    cursor: pointer;
    margin: 0 5px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .img-view {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .info-view {
        color: white;
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-size: 18px;
    }
}

.nav-item {
    width: 180px;

    .info-view {
        display: none;
    }
}
.active {
    // transform: scale(2, 1);
    width: 450px;
    .info-view {
        display: block;
    }
}
</style>